export const columns_config = () => {
	return [{
			name: "退货物流单号",
			nameEn:"tracking No",
			data: 'tracking',
			// editor: 'select',
			// selectOptions: []
			type: 'text',
			// type: 'dropdown',
			// source: []
			// width: 200,
		},
		{
			name: "SKU",
			nameEn:"",
			data: 'goodsSku',
			type: 'text',
		},

		{
			name: "数量",
			nameEn:"QT",
			data: 'quantity',
			type: 'numeric',
		},
		// {
		// 	name: "ID",
		// 	data: 'id',
		// 	type: 'text',
		// 	readOnly: true
		// },
	];;
}

export const nestedHeadersTop_config = () => {
	return []
}