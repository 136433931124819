<template>
	<el-popover trigger="hover" placement="right" :title="$t('i18nn_41413cddfa61538c')">
	
		<!-- <el-alert :title="$t('i18nn_41413cddfa61538c')" type="info" show-icon description=""> -->
		<div style="font-size: 14px;line-height: 150%;">
			<div>
				<span v-if="2==type">{{$t('tips.long_TipsActionHotTable_1_1')}}</span>
				<span v-else>{{$t('tips.long_TipsActionHotTable_1')}}</span>
			</div>
			<div>{{$t('tips.long_TipsActionHotTable_2')}}</div>
			<div>{{$t('tips.long_TipsActionHotTable_3')}}</div>
		</div>
		<!-- </el-alert> -->
	
		<!-- <el-button slot="reference">{{$t('i18nn_41413cddfa61538c')}}</el-button> -->
		<el-link type="warning" slot="reference"><span>{{$t('i18nn_41413cddfa61538c')}}</span><i class="el-icon-info  el-icon--right"></i>
		</el-link>
	</el-popover>
</template>

<script>
	export default {
		props: {
			// mobile:"",
			type: {
				// default: function() {
				//   return false;
				// },
				// type: Boolean
			},
		},
	}
</script>

<style>
</style>