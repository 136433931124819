<template>
	<div class="mainTem">
		<div class="buyerHeader">
			<el-page-header @back="goBack">
				<!-- <template slot="title">title</template> -->
				<template slot="content">
					<template v-if="10 == orderType"></template>
					<template v-else-if="20 == orderType"></template>
					<template v-else-if="30 == orderType">{{ $t('i18nn_6b1945319eb18d5f') }}</template>
					<template v-else-if="40 == orderType">{{$t('Storage.nav.A_undertakes_to')}}</template>
					<template v-else-if="50 == orderType">{{ $t('i18nn_199f5bded0b23976') }}</template>
					<template v-else-if="60 == orderType">{{$t('Storage.nav.My_packing_list')}}</template>
				</template>
			</el-page-header>

		</div>


		<div style="padding:10px; background-color: #FFFFFF;">
			<!-- <div v-if="10 == orderType || 20 == orderType"><questionTem @submitSuccess="goWorkOrderList"></questionTem></div> -->
			<!-- <div v-else-if="30 == orderType"><TransshipmentTem @submitSuccess="goBack"></TransshipmentTem></div> -->
			<div v-if="30 == orderType">

				<el-tabs v-model="transfer_activeName">
					<el-tab-pane :label="$t('i18nn_c996cb376f253cb5')" name="first">
						<transferOrderTem ref="transferOrderTem" @submitSuccess="goBack"></transferOrderTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_844521f361060167')" name="second">
						<transferOrderSingleAdd ref="transferOrderSingleAdd" @submitSuccess="goBack"></transferOrderSingleAdd>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div v-else-if="40 == orderType">
				<!-- <dropShippingTem @submitSuccess="goBack"></dropShippingTem> -->
				
				<el-tabs v-model="dropShipping_activeName">
					<el-tab-pane :label="$t('i18nn_c996cb376f253cb5')" name="first">
						<dropShippingTem ref="dropShippingTem" @submitSuccess="goBack"></dropShippingTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_844521f361060167')" name="second">
						<dropShippingSingleAdd ref="dropShippingSingleAdd" @submitSuccess="goBack"></dropShippingSingleAdd>
					</el-tab-pane>
				</el-tabs>
				
			</div>
			<div v-else-if="50 == orderType">
				
				<el-tabs v-model="ExchangeReturn_activeName">
					<el-tab-pane :label="$t('i18nn_7c5912ab3762973a')" name="first">
						<ExchangeReturn ref="ExchangeReturn" @submitSuccess="goBack"></ExchangeReturn>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_844521f361060167')" name="second">
						<ExchangeReturnSingleAdd ref="ExchangeReturnSingleAdd" @submitSuccess="goBack"></ExchangeReturnSingleAdd>
					</el-tab-pane>
				</el-tabs>
			</div>
			<div v-else-if="60 == orderType">
				
				<el-tabs v-model="packing_activeName">
					<el-tab-pane :label="$t('i18nn_7c5912ab3762973a')" name="first">
						<PackingWarehousingTem ref="PackingWarehousingTem" @submitSuccess="goBack"></PackingWarehousingTem>
					</el-tab-pane>
					<el-tab-pane :label="$t('i18nn_844521f361060167')" name="second">
						<PackingWarehousingSingleAdd ref="PackingWarehousingSingleAdd" @submitSuccess="goBack"></PackingWarehousingSingleAdd>
					</el-tab-pane>
				</el-tabs>
			</div>
		</div>
		
		<!-- <div class="submit-footer">
			<el-button type="warning" @click="submitForm()" style="width: 200px;">{{$t('i18nn_e186babc2e9cadf4')}}</el-button>
		</div> -->
		
	</div>
</template>
<script>
	// import questionTem from '@/components/StorageCenter/workOrder/workOrderTem/questionTem.vue';
	// import TransshipmentTem from '@/components/StorageCenter/workOrder/workOrderTem/TransshipmentTem.vue';
	import transferOrderTem from '@/components/StorageCenter/workOrder/workOrderTem/transferOrderTem.vue';
	import dropShippingTem from '@/components/StorageCenter/workOrder/workOrderTem/dropShippingTem.vue';
	import PackingWarehousingTem from '@/components/StorageCenter/workOrder/workOrderTem/PackingWarehousingTem.vue';
	import ExchangeReturn from '@/components/StorageCenter/workOrder/workOrderTem/ExchangeReturn.vue';

	import transferOrderSingleAdd from '@/components/StorageCenter/workOrder/workOrderTem/transferOrderSingleAdd.vue';
	import PackingWarehousingSingleAdd from '@/components/StorageCenter/workOrder/workOrderTem/PackingWarehousingSingleAdd.vue';
	import dropShippingSingleAdd from '@/components/StorageCenter/workOrder/workOrderTem/dropShippingSingleAdd.vue';
	import ExchangeReturnSingleAdd from '@/components/StorageCenter/workOrder/workOrderTem/ExchangeReturnSingleAdd.vue';
	
	export default {

		components: {
			// questionTem,
			// TransshipmentTem,
			transferOrderTem,
			dropShippingTem,
			PackingWarehousingTem,
			ExchangeReturn,
			transferOrderSingleAdd,
			PackingWarehousingSingleAdd,
			dropShippingSingleAdd,
			ExchangeReturnSingleAdd
		},
		data() {
			return {

				hasEdit: false,
				orderType: "",
				selectOption: {
					wh_work_order_type: [],
				},
				transfer_activeName: "first",
				packing_activeName:"first",
				dropShipping_activeName:"first",
				ExchangeReturn_activeName:"first",
			};
		},

		//创建时
		created() {
			// this.getPageData();
			console.log('$route.query',this.$route.query);
			if (this.$route.query && this.$route.query.orderType) {
				this.orderType = this.$route.query.orderType;
				console.log('single',this.$route.query.single);
				if('true'==this.$route.query.single||true==this.$route.query.single){
					if('60'==this.orderType){
						this.packing_activeName = "second";
					} else if('30'==this.orderType){
						this.transfer_activeName = "second";
					} else if('40'==this.orderType){
						this.dropShipping_activeName = "second";
					} else if('50'==this.orderType){
						this.ExchangeReturn_activeName = "second";
					}
					
				}
			} else {
				// this.orderType = 10;
			}
			// this.$forceUpdate();
			// this.getDicData();

			this.initData();
		},
		//编译挂载前
		mounted() {

		},
		methods: {
			initData() {
				if (this.$route.query.id) {

				} else {
					this.hasEdit = true;
				}
			},
			goBack() {
				this.$router.go(-1);
			},
			goWorkOrderList() {
				this.$router.go(-1);
				// this.$router.push({ name: 'workOrderList' });
			},
			changeOrderType(v) {
				console.log(v);
			},
			// submitForm(){
			// 	this.$nextTick(()=>{
			// 		if(30 == this.orderType){
			// 			if('first'==this.transfer_activeName){
			// 				this.$refs.transferOrderTem.submitForm('form');
			// 			} else if('second'==this.transfer_activeName){
			// 				this.$refs.transferOrderSingleAdd.submitForm('form');
			// 			}
			// 		} else if(40 == this.orderType){
			// 			if('first'==this.dropShipping_activeName){
			// 				this.$refs.dropShippingTem.submitForm('form');
			// 			} else if('second'==this.dropShipping_activeName){
			// 				this.$refs.dropShippingSingleAdd.submitForm('form');
			// 			}
			// 		} else if(50 == this.orderType){
			// 			if('first'==this.ExchangeReturn_activeName){
			// 				this.$refs.ExchangeReturn.submitForm('form');
			// 			} else if('second'==this.ExchangeReturn_activeName){
			// 				this.$refs.ExchangeReturnSingleAdd.submitForm('form');
			// 			}
			// 		} else if(60 == this.orderType){
			// 			if('first'==this.packing_activeName){
			// 				this.$refs.PackingWarehousingTem.submitForm('form');
			// 			} else if('second'==this.packing_activeName){
			// 				this.$refs.PackingWarehousingSingleAdd.submitForm('form');
			// 			}
			// 		}
			// 	})
				
			// },
			//查询数据字典
			// getDicData() {
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_work_order_type'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				this.selectOption.wh_work_order_type = data.data['wh_work_order_type'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less"></style>
